import { useSearchParams } from "@remix-run/react";

export function useQueryParams() {
	const [searchParams, setSearchParams] = useSearchParams();

	function handleQueryChange({
		key,
		value,
		mustDelete = false,
		isArray = false,
	}: {
		key: string;
		value: string | boolean;
		mustDelete?: boolean;
		isArray?: boolean;
	}) {
		const prevParams = searchParams;
		const parsedValue = typeof value === "boolean" ? value.toString() : value;

		if (isArray) {
			const values = [...new Set(prevParams.getAll(key))];
			if (mustDelete && values.includes(parsedValue)) {
				values.splice(values.indexOf(parsedValue), 1);
			} else {
				values.push(parsedValue);
			}

			prevParams.delete(key);
			for (const value of values) {
				prevParams.append(key, value);
			}
		} else {
			if (mustDelete) {
				prevParams.delete(key);
			} else {
				prevParams.set(key, parsedValue);
			}
		}

		setSearchParams(prevParams);
	}

	function isQueryActive(key: string, value: string, isArray = false) {
		if (isArray) {
			return searchParams.getAll(key).includes(value);
		}

		return searchParams.has(key) && searchParams.get(key) === value;
	}

	return {
		searchParams,
		handleQueryChange,
		isQueryActive,
	};
}
